import React, { useState, useContext, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { IconButton, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import * as singleSpa from 'single-spa';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  FdTopNav,
  FdDropdownMenu,
  FdAvatar,
  AuthContext,
  globalStore,
  useSnapshot,
  useInitializeGlobalStore,
  useClipboard,
  getAvatarColor,
  getAvatarName,
  FdTooltip,
  PERMISSIONS_AFFILIATED,
  Authorization,
  orgDetailsPermissions,
} from '@fifthdomain/fe-shared';
import { getOrg } from '../../graphql/queries';
import { successToastMessage } from '../../shared/toast';
import '../../navbar.css';

const LandingTopNav = () => {
  const [profileAnchor, setProfileAnchor] = useState(null);
  const [hideMyTeams, setHideMyTeams] = useState(false);
  const { handleSignOut, setUser } = useContext(AuthContext);
  const history = useHistory();
  const theme = useTheme();
  const snap = useSnapshot(globalStore);
  const [copyToClipboard] = useClipboard();

  const { data: orgData, loading: orgLoading } = useQuery(gql(getOrg), {
    variables: {
      id: snap?.orgId,
    },
    skip: !snap?.orgId,
  });

  useEffect(() => {
    getCurrentUser()
      .then(async (userData) => {
        const session = await fetchAuthSession();
        const userSessionData = {
          ...userData,
          signInUserSession: { accessToken: session?.tokens?.accessToken },
          email: session?.tokens?.idToken?.payload?.email,
        };
        setUser(userSessionData);
        // add logged in user to global state
        globalStore.user = userData;
      })
      .catch((err) => {
        // refresh aws-amplify if logins are not matched (amplify cache is not updated on re-login)
        if (err.message.includes('at least one valid login')) {
          window.location.reload();
        } else {
          console.log('error: ', err);
          singleSpa.navigateToUrl('/');
        }
      });
  }, [setUser]);

  // hide teams button if already in teams page
  const hideMyTeamsButton = (location) =>
    setHideMyTeams(location.pathname.includes('/landing/teams'));

  useEffect(() => {
    // for initial render
    hideMyTeamsButton(history.location);
    // listen for url changes
    const unListen = history.listen((location) => hideMyTeamsButton(location));
    return () => {
      unListen();
    };
  }, [history]);

  // initialize globalStore from host app
  useInitializeGlobalStore();

  const pathName = history.location.pathname;
  const { userType, isAAFCOrg } = snap;
  const isParticipant = userType === 'PARTICIPANT';

  const menuCallBack = async (event) => {
    const value = event.target.id || event.currentTarget.id;
    switch (value) {
      case 'manage_profile':
        singleSpa.navigateToUrl('/landing/user/profile');
        break;
      case 'org_alias':
        copyToClipboard(orgData?.getOrg?.alias);
        successToastMessage('Copied to clipboard!');
        break;
      case 'org_Profile':
        singleSpa.navigateToUrl('/landing/org/profile');
        break;
      default:
        await handleSignOut();
        break;
    }
  };

  if (snap.loading || orgLoading) return null;

  const getMenuOptions = () => {
    if (isParticipant && /\/assessor\/survey\/?$/.test(pathName)) {
      return [{ id: 'sign_out', name: 'Sign Out' }];
    }

    const menuOptions = [
      { id: 'manage_profile', name: 'Manage Profile' },
      {
        id: 'org_Profile',
        name: 'Organisation Details',
        permissions: orgDetailsPermissions,
      },
      {
        id: 'org_alias',
        name: `${orgData?.getOrg?.alias}`,
        icon: () => (
          <FileCopyIcon style={{ color: '#757575', marginLeft: '8px' }} />
        ),
        iconDirection: 'right',
        // show only if user has these permissions
        permissions: [PERMISSIONS_AFFILIATED.MANAGE_CONTENT],
      },
      { id: 'sign_out', name: 'Sign Out' },
    ];
    const menuOptionsById = (ids) =>
      menuOptions.filter((option) => {
        if (option.permissions) {
          // If permissions are defined, check if the user has those permissions
          return (
            ids.includes(option.id) &&
            Authorization.hasPermission(snap.permissions, option.permissions)
          );
        }
        return ids.includes(option.id);
      });

    return isParticipant && isAAFCOrg
      ? menuOptionsById(['manage_profile', 'sign_out'])
      : isParticipant && !isAAFCOrg
      ? menuOptionsById(['manage_profile', 'sign_out'])
      : userType === 'MANAGER'
      ? menuOptionsById(['manage_profile', 'org_alias', 'sign_out'])
      : menuOptionsById([
          'manage_profile',
          'org_Profile',
          'org_alias',
          'sign_out',
        ]);
  };
  const menuOptions = getMenuOptions();

  const rightComponents = [
    ...(isParticipant && !hideMyTeams
      ? [
          // eslint-disable-next-line react/jsx-indent
          <FdTooltip title="My Teams">
            <span className="mr-2">
              <FdAvatar
                size="small"
                variant="circular"
                color={theme.palette.mode === 'dark' ? '#000' : '#fff'}
                content={
                  <IconButton size="large">
                    <SupervisedUserCircleRoundedIcon
                      style={{
                        fontSize: 38,
                        fill:
                          theme.palette.mode === 'dark'
                            ? 'rgb(158, 158, 158)'
                            : 'rgba(0, 0, 0, 0.65)',
                      }}
                    />
                  </IconButton>
                }
                onClick={() => singleSpa.navigateToUrl('/landing/teams')}
                fillIcon
              />
            </span>
          </FdTooltip>,
        ]
      : []),
    <FdAvatar
      color={getAvatarColor(snap.userName)}
      size="small"
      variant="circular"
      content={getAvatarName(snap.userName) || ''}
      onClick={(event) => setProfileAnchor(event.currentTarget)}
      style={{ cursor: 'pointer' }}
    />,
    <FdDropdownMenu
      id="profile_menu"
      menuOptions={menuOptions}
      anchor={profileAnchor}
      setAnchor={setProfileAnchor}
      menuCallback={menuCallBack}
      variant="medium"
      closeOnSelect
    />,
  ];

  return <FdTopNav rightComponents={rightComponents} />;
};

export default LandingTopNav;
