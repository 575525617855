import './set-public-path';
import React from 'react';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import * as ReactDOMClient from './ReactDOMClient';

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Root,
  errorBoundary(_err, _info, _props) {
    return <div>Error</div>;
  },
});

export const { bootstrap } = lifecycles;
export const { mount } = lifecycles;
export const { unmount } = lifecycles;
